import {getPromoteUser, isDesk} from "@/utils/utils";
import {catch_http, httpPlus} from "./request";

// 手机号登录
export const loginByPhone = (data: object) =>
  catch_http("post", "/chat-server/api/user/login-by-phone", data, {
    headers: getPromoteUser(),
  });

// 发送手机验证码
export const sendLoginCode = (data: object) =>
  catch_http("post", "/chat-server/api/user/send-login-code", data);

// 密码登录
export const loginByPasswd = (data: object) =>
  catch_http("post", "/chat-server/api/user/login-by-passwd", data);

// 获取腾讯验证码参数
export const tencentCloudInfo = () =>
  catch_http("post", "/chat-server/api/tencent/cloud/info");

// 退出登录
export const logout = () => catch_http("post", "/chat-server/api/user/logout");

// 重置密码验证码
export const sendResetCode = (data: object) =>
  catch_http("post", "/chat-server/api/user/send-reset-code", data);

// 重置密码
export const resetByPhone = (data: object) =>
  catch_http("post", "/chat-server/api/user/reset-by-phone", data);

// 用户剩余免费次数
export const userFreeTimes = () =>
  catch_http("post", "/chat-server/api/user/free-times");

// 注销
export const sginOut = (params: object) =>
  catch_http("post", "/chat-server/logoff/add", params);

// 注销验证码
export const sendLoginffCode = (data: object) =>
  catch_http("post", "/chat-server/api/user/send-logoff-code", data);

// 根据手机号撤销注销
export const logoffRevoke = (phone: string) =>
  catch_http("post", "/chat-server/logoff/revoke/" + phone);
// 获取微信登录二维码配置
export const wxConfig = () =>
  catch_http("get", "/chat-server/api/wechat/login/qrcode");

// 获取微绑定二维码配置
export const wxBindConfig = () =>
  catch_http("get", "/chat-server/api/wechat/bind/qrcode");

export const wxScanResult = (currentToken: string) =>
  catch_http("post", "/chat-server/api/wechat/open-wx/scan", {currentToken});

// 扫码结果回调
export const codeResult = (currentToken: any) =>
  catch_http(
    "post",
    `/chat-server/api/wechat/open-wx/scan${isDesk() ? '/v2' : ''}`,
    {
      currentToken: currentToken
    }
  );
// 验证身份跳过
export const bindGo = (currentToken: any) =>
  catch_http(
    "post",
    "/chat-server/wechat/bind/skip",
    {
      currentToken: currentToken
    }
  );
// 发送验证码
export const sendSmsCode = (params: any) =>
  catch_http("post", "/chat-server/api/user/send-sms-code", params);
// 确定绑定
export const comfirmBind = (params: any) =>
  catch_http("post", "/chat-server/api/wechat/bind/mobile", params);
// 判断用户是否需要绑定手机号
export const hasBind = (currentToken: string) =>
  catch_http(
    "post",
    `/chat-server/api/wechat/is-bind-phone${isDesk() ? '/v2' : ''}`,
    {
      currentToken: currentToken
    }
  );

// 获取用户登录身份信息
export const requestUserInfo = function () {
  const url = `/chat-server/api/user/get-login-user`

  return httpPlus<NUser.IUserInfo>("get", url)
};
