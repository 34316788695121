import { GptModeType } from "@/pages/chat-int/types";
import { conversationPageList } from "@/server/lb-chat";
import { Payload, RootState } from "@/types/type";
import { EffectsCommandMap, getDvaApp } from "@umijs/max";
import {
  getChatPluginList,
  type IResponsePluginItem,
} from "@/server/chat-plugins";
import { ResponseData } from "@/types/type";
import { getGptModesV2 } from "@/server/lb-chat-prompt";
import { useGptModelList } from "@/pages/chat-int/hooks";

export interface IPluginItem {
  name: string;
  id: string;
  title: string;
  illustrate: string[];
  example: string;
}

export interface ChatParameter {
  chatTextarea?: string;
  initChatTextarea?: string;
  chatApi?: any;
  hasConversation?: boolean;
  chatReadingState?: boolean;
  chatMessageResult?: any;
  chatConversationList?: any;
  conversationItem?: Record<string, any>;
  chatCurrentMessage?: any;
  conversationPending?: boolean;
  conversationPages?: any;
  conversationHistoryList?: any;
  isScrollEnd?: boolean;
  hasSmallMenu?: boolean;
  currentType: NConversation.TConversationType;
  hasChatTextareaFocus?: boolean;
  hasChatStream?: boolean;
  chatItemCount: number;
  hasChatStreamCode?: string;
  gptMode: GptModeType;
  uploadFileList: any[] | null;
  lastUploadFile: any;
  showUploadFile: boolean;
  showFileRequestions: boolean;
  chatFileRefreshId: string | number;
  aiPolling: boolean;
  editDisabled: boolean;
  showOptimizeView: boolean;
  drawOption: any;
  pollItem: null;
  gptModeList: GptModeType[];
  gptModeListMap: Record<string, GptModeType>;
  messagePolledData: Record<string, any>;
  uuid: number;
  addNewChatUuid: 0;
  editMessageId: string;
  beforeProblemId?: string;
  developeMode: boolean; // 程序员模式
  isShowHistoryPop: boolean;
  allPlugins: Array<IPluginItem> | any;
  enablePlugins: string[] | any; // 启用的插件
  hasCollectTip: boolean; //收藏提示开关
  chatInputImage: {
    fileType: string;
    url: string;
    imageRecognitionId: string;
  } | null;
  chatUploadFiles:
    | {
        fileType: string;
        fileId: string;
        fileName: string;
      }[]
    | null;
  filterModel: string; // 首页筛选模型
  showTour: boolean; // 首页个人模板引导
  prefixMenuDropSelectType: string | null; // 首页功能体验是否是下拉触发
  payConfigUserInfo: NVipPay.PayUserDataType | {};
  GPT_MODEL3_5: GptModeType;
  GPT_MODEL4: GptModeType;
  CAN_USE_GPT: boolean;
  currentImageId: string | any; // 图片解析 最新上传的那个图片id
  isNewPage: boolean;
  generalLeftHistoryItem: NLeftGeneralHistoryX.TRecord | null;
  homeFunctionList: NNewHome.IHomeFunctionAllListV2;
  showVideoTips: boolean; // 添加新的状态
  videoTipsFreeTimes: number; // 视频免费次数
}

export const getGPT3_5 = function (): GptModeType {
  return {
    description: "使用最多的模型，能够进行广泛领域的自然对话和问题解答",
    isDefault: true,
    isRequireYearVip: false,
    model: window.defaultModel.model,
    modelCategory: window.defaultModel.name,
    name: window.defaultModel.name,
    showName: "",
    children: [],
  };
};
export const getGPT4 = function (): GptModeType {
  return {
    name: "GPT-4",
    showName: "",
    model: "gpt-4",
    description: "我们最好的模型，适合创造力和高级推理能力的任务",
    isDefault: false,
    isRequireYearVip: true,
    modelCategory: "GPT-4",
    children: [],
  };
};

export default {
  state: {
    // 往输入框插入内容
    chatTextarea: "",
    // 对话页面输入框初始值
    initChatTextarea: "",
    // chat的api方法
    chatApi: null,

    gptMode: getGPT3_5(),
    GPT_MODEL3_5: getGPT3_5(),
    GPT_MODEL4: getGPT4(),
    // 模型列表
    gptModeList: [],
    gptModeListMap: {},
    CAN_USE_GPT: false,

    // 是否显示会话列表
    hasConversation: false,
    // 会话流状态
    chatReadingState: false,
    // 会话msg列表
    chatMessageResult: [],
    // 左侧会话列表
    chatConversationList: {},
    // 会话选中的对象
    conversationItem: {},
    // 当前会话msg
    chatCurrentMessage: "",
    // 会话接口pending loading
    conversationPending: false,
    conversationPages: {
      current: 1,
      size: 10,
    },
    // 会话历史
    conversationHistoryList: [],
    //滚动到底部才可以触顶加载
    isScrollEnd: false,

    // 是否打开小屏菜单
    hasSmallMenu: false,
    // 当前的选中的类型  conversation 会话   painting 绘画   paintDalle Dalle绘画 video 视频
    currentType:
      location.pathname.indexOf("/painting") !== -1
        ? "painting"
        : location.pathname.indexOf("/ai-video") !== -1
        ? "video"
        : "conversation",
    // 输入框是否聚焦
    hasChatTextareaFocus: false,
    // 当前会话是否已写完
    hasChatStream: false,
    chatItemCount: 0,
    // chat流状态码
    hasChatStreamCode: "0",
    // 最近上传的文档
    uploadFileList: null,
    // 上一次上传的文件
    lastUploadFile: null,
    // 上传文件相关轮询
    aiPolling: false,
    // 解析文件刷新id
    chatFileRefreshId: 0,
    // 是否展示文件关联问题
    showFileRequestions: false,
    // 输入框是否禁止输入
    editDisabled: false,
    // 显示上传icon
    showUploadFile: false,
    //优化界面
    showOptimizeView: false,
    //绘画要求
    drawOption: {},
    // 改写 缩写 项 上传文件提问
    pollItem: null,

    messagePolledData: {},
    // uuid 仅做视图刷新
    uuid: 0,
    // 当前编辑的会话id
    editMessageId: "",
    // 切换会话的上一个用户会话id
    beforeProblemId: "",
    developeMode: false,
    // 移动端历史记录弹窗显隐
    isShowHistoryPop: false,
    addNewChatUuid: 0,
    allPlugins: [],
    enablePlugins: [],
    hasCollectTip: localStorage.getItem("hasCollectTip") === "1" ? false : true, //收藏提示开关
    chatInputImage: null, // 会话上传图片
    chatUploadFiles: null, // 文件解读-上传的多文件
    filterModel: "",
    showTour: false,
    prefixMenuDropSelectType: null,
    payConfigUserInfo: {},
    currentImageId: null,
    isNewPage: false,
    generalLeftHistoryItem: null,
    homeFunctionList: {
      chatFeatureList: [],
      paintFeatureList: [],
      academicFeatureList: [],
      jobFeatureList: [],
      paperFeatureList: [],
    },
    showVideoTips: false, // 初始状态为false
    videoTipsFreeTimes: 0, // 视频免费次数
  } as ChatParameter,

  reducers: {
    setPayConfigUserInfo: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        payConfigUserInfo: { ...payload },
      };
    },
    setUploadFileList: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        uploadFileList: payload,
      };
    },
    setDevelopeMode: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        developeMode: payload,
      };
    },
    setChatTextarea: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatTextarea: payload,
      };
    },
    setInitChatTextarea: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        initChatTextarea: payload,
      };
    },
    setChatApi: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatApi: payload,
      };
    },
    setGptVersion: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        gptMode: payload,
        enablePlugins: payload !== state.gptMode ? [] : state.enablePlugins,
      };
    },
    setHasConversation: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        hasConversation: payload,
      };
    },
    setChatReadingState: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatReadingState: payload,
      };
    },
    setChatMessageResult: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatMessageResult: payload,
      };
    },
    setChatConversationList: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatConversationList: {
          ...payload,
          records: payload?.records?.map((item: any) => {
            return {
              ...item,
              currentType: "conversation",
            };
          }),
        },
      };
    },
    setConversationItem: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        conversationItem: payload,
      };
    },
    setGeneralLeftHistoryItem: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        generalLeftHistoryItem: payload,
      };
    },
    setHomeFunctionList: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        homeFunctionList: payload,
      };
    },
    setChatCurrentMessage: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatCurrentMessage: payload,
      };
    },
    setConversationPending: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        conversationPending: payload,
      };
    },
    setConversationPages: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        conversationPages: payload,
      };
    },
    setConversationHistoryList: (
      state: ChatParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        conversationHistoryList: payload,
      };
    },
    setIsScrollEnd: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        isScrollEnd: payload,
      };
    },
    setHasSmallMenu: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        hasSmallMenu: payload,
      };
    },
    setCurrentType: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        currentType: payload,
      };
    },
    setHasChatTextareaFocus: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        hasChatTextareaFocus: payload,
      };
    },
    setHasChatStream: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        hasChatStream: payload,
      };
    },

    setChatItemCount: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatItemCount: payload,
      };
    },
    setHasChatStreamCode: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        hasChatStreamCode: payload,
      };
    },
    setLastUploadFile: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        lastUploadFile: payload,
      };
    },
    setAiPolling: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        aiPolling: payload,
      };
    },
    setChatFileRefreshId: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatFileRefreshId: payload,
      };
    },
    setShowFileRequestions: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        showFileRequestions: payload,
      };
    },
    setEditDisabled: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        editDisabled: payload,
      };
    },
    setShowUploadFile: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        showUploadFile: payload,
      };
    },
    setShowOptimizeView: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        showOptimizeView: payload,
      };
    },
    setDrawOption: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        drawOption: payload,
      };
    },
    setPollItem: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        pollItem: payload,
      };
    },
    setGptModeList: (state: ChatParameter, { payload }: Payload) => {
      let gptMode = state.gptMode;

      if (!payload.find((a: any) => a.name === gptMode?.name)) {
        gptMode = payload.find((a: any) => !!a.isDefault) || payload[0];
      }

      let gpt3_5Config: GptModeType =
        payload.find(
          (n: GptModeType) => n.model === window.defaultModel.model,
        ) || state.GPT_MODEL3_5;
      let gpt4Config: GptModeType =
        payload.find((n: GptModeType) => n.model === "gpt-4") ||
        state.GPT_MODEL4;

      // if(gpt3_5Config){
      //   gpt3_5Config.showName ='XX3'
      // }
      // if(gpt4Config){
      //   gpt4Config.showName ='XX4'
      // }

      const gptModeListMap: Record<string, GptModeType> = {
        [gpt3_5Config.model]: gpt3_5Config,
        [gpt4Config.model]: gpt4Config,
      };

      payload.forEach((n: GptModeType) => {
        gptModeListMap[n.model] = n;
      });

      return {
        ...state,
        gptModeList: payload,
        gptMode,
        GPT_MODEL3_5: gpt3_5Config,
        GPT_MODEL4: gpt4Config,
        gptModeListMap,
        CAN_USE_GPT: !!gpt4Config.showName,
      };
    },
    setMessagePolledData: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        messagePolledData: payload,
      };
    },
    setUuid: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        uuid: payload,
      };
    },
    setAddNewChatUuid: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        addNewChatUuid: payload,
      };
    },
    setEditMessageId: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        editMessageId: payload,
      };
    },
    setBeforeProblemId: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        beforeProblemId: payload,
      };
    },
    setIsShowHistoryPop: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        isShowHistoryPop: payload,
      };
    },
    setAllPlugins: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        allPlugins: payload,
      };
    },
    setEnablePlugins: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        enablePlugins: payload,
      };
    },
    setHasCollectTip: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        hasCollectTip: payload,
      };
    },
    setChatInputImage: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatInputImage: payload,
      };
    },
    addChatUplaodFiles: (state: ChatParameter, { payload }: Payload) => {
      const arr = [...(state.chatUploadFiles || [])];

      return {
        ...state,
        chatUploadFiles: [...arr, ...payload],
      };
    },
    setChatUploadFiles: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        chatUploadFiles: payload,
      };
    },
    setFilterModel: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        filterModel: payload,
      };
    },
    setShowTour: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        showTour: payload,
      };
    },
    setprefixMenuDropSelectType: (
      state: ChatParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        prefixMenuDropSelectType: payload,
      };
    },
    setCurrentImageId: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        currentImageId: payload,
      };
    },
    setIsNewPage: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        isNewPage: payload,
      };
    },
    setShowVideoTips: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        showVideoTips: payload,
      };
    },
    setVideoTipsFreeTimes: (state: ChatParameter, { payload }: Payload) => {
      return {
        ...state,
        videoTipsFreeTimes: payload,
      };
    },
  },
  effects: {
    // 获取左侧会话列表
    *getConversationPageList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      try {
        const res: any = yield call(conversationPageList, payload);
        if (res?.code == 0) {
          yield put({
            type: "setChatConversationList",
            payload: res.data,
          });

          const chatStore: any = yield select((state: RootState) => {
            return state.chatParameter;
          });

          const conversationItem: any = chatStore.conversationItem;

          // 更新一下当前会话
          if (conversationItem && !conversationItem.model) {
            const current = res.data.records.find(
              (item: any) => item.id === conversationItem.id,
            );
            if (current) {
              yield put({
                type: "setConversationItem",
                payload: current,
              });
            }
          }
        }
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },

    // 获取插件列表
    *getChatPlugins(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const res = (yield call(getChatPluginList)) as ResponseData<
        IResponsePluginItem[]
      >;
      if (res.code === 0) {
        yield put({
          type: "setAllPlugins",
          payload: res.data.map((item) => {
            return {
              id: item.diagramTypeCode,
              name: item.diagramTypeName,
              title: item.diagramTitle,
              illustrate: item.diagramContent.split(/\r?\n/g),
              example: item.diagramImgUrl,
            };
          }) as IPluginItem[],
        });
      }
    },

    // 请求并设置模型列表
    *initSetModelList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const res = (yield call(getGptModesV2)) as ResponseData<GptModeType[]>;

      if (res.code === 0) {
        yield put({
          type: "setGptModeList",
          payload: useGptModelList(res.data),
        });
      }
    },
  },
};
