/*
 * 公共弹框
 * */
import { Payload, RootState } from "@/types/type";

export default {
  state: {
    // 支付开通记录弹框
    showPayHistoryPop: false,

    // 补差价升级弹框
    showVipUpgradePop: false,

    // 视频支付弹框
    showVideoPayPop: false,
  } as NCommonPops.IModelState,

  reducers: {
    set: (state: NCommonPops.IModelState, { payload }: Payload) => {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: {},
};
