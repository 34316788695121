import { API_HOST, PubSubType } from "@/const";
import { Payload, RootState } from "@/types/type";
import { eventSource } from "@/utils/eventSource";
import { EffectsCommandMap, getDvaApp, useNavigate } from "@umijs/max";
import { getLoginToken } from "./userInfo";
import { ChatParameter } from "./chatParameter";
import { getChatTextLengthTip, getRouterParams } from "@/utils/utils";
import { conversationSessionId } from "@/server/lb-chat";
import { getDav } from "@/utils";
import { message } from "antd";
import { userFreeTimes } from "@/server/lb-login-auth";
import { IChatOptimizeQuestionStore } from "./chatOptimizeQuestion";
import PubSub from "pubsub-js";

interface ChatGptConfig {
  frequency_penalty: number;
  max_tokens: number;
  model: string;
  presence_penalty: number;
  temperature: number;
  top_p: number;
  fileDataId?: string;
  // 多文件对话
  gptFileIds?: string[];
  fileMarkType?: string;
  sceneInfo: string;
  useInternet: 0 | 1;
  messages: Array<any>;
  beforeProblemId?: string;
  isContinue?: boolean;
  pluginType?: string[];
  sessionId?: string;
  rolePlayId?: string;
  promptId?: string | number;
  imageRecognitionId?: string;
  operationType?: string;
  editChatMessage?: boolean;
  editMessageId?: string;
  regenerateAnswerId?: string;
}
export interface ChatMessage {
  chatPending: boolean;
  chatMessageList: NConversation.ChatMessageListItem[];
  historyLoading: boolean;
  chatGptConfig: ChatGptConfig;
  cancelToken: AbortController;
  errorCode: Array<number | string>;
  userFreeTimesCount: NConversation.userFreeTimesCountTs;
  isErrorReload?: boolean;
}

// 调试代码
setTimeout(() => {
  window.xx = getDvaApp;
}, 2000);

const getDvaStore = () => {
  return getDvaApp()._store;
};

const updataLeftSideHistory = () => {
  const rootState = getDvaStore().getState() as RootState;
  // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用
  if (
    rootState.chatMessage.chatMessageList.length > 0 &&
    rootState.chatMessage.chatMessageList.length <= 2
  ) {
    if (
      rootState.chatMessage.chatMessageList &&
      rootState.chatMessage.chatMessageList[0] &&
      rootState.leftSideX.histortListData?.records &&
      rootState.leftSideX.histortListData?.records[0] &&
      rootState.chatMessage.chatMessageList[0].sessionId !==
        rootState.leftSideX.histortListData?.records[0].id
    ) {
      PubSub.publish(PubSubType.RESET_LEFT_HISTORY, true);
    }
  }

  const newLastItem = getDvaStore()
    .getState()
    .chatMessage.chatMessageList.at(-1);
  const oldLastItem = getDvaStore()
    .getState()
    .chatMessage.chatMessageList.at(-3);
  console.log(newLastItem, oldLastItem, "newLastItem, oldLastItem");

  // 文件解读
  if (
    newLastItem?.fileDataId &&
    newLastItem?.fileDataId !== oldLastItem?.fileDataId
  ) {
    PubSub.publish(PubSubType.RESET_LEFT_HISTORY, true);
  }

  // 多文件对话
  if (
    newLastItem?.gptFiles !== oldLastItem?.gptFiles &&
    !!newLastItem?.gptFileIds?.length
  ) {
    PubSub.publish(PubSubType.RESET_LEFT_HISTORY, true);
  }
  // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用  END
};

export const getDefaultChatGptConfig = () => {
  return {
    // gpt相关配置信息
    frequency_penalty: 0.0,
    max_tokens: 512,
    model: window.defaultModel.model,
    presence_penalty: 0.0,
    temperature: 0.73,
    top_p: 1.0,
    sceneInfo: "lb-chat-ui",
    sessionId: "",
    messages: [],
    useInternet: 0,
    regenerateAnswerId: "",
  };
};

export default {
  state: {
    chatPending: false, // ai回答sse请求loading
    chatMessageList: [], // 对话列表
    historyLoading: false, // 历史数据加载loading
    chatGptConfig: getDefaultChatGptConfig(),
    cancelToken: new AbortController(), // 取消请求
    isErrorReload: false, // 是否是错误重载
    errorCode: [
      "-20010",
      "-2002",
      "-2004",
      "-2006",
      "-2007",
      "-2019",
      "-2039",
      "-2021",
      "-2022",
      "-20004",
      "-2040",
      "-2041",
      "-2042",
      "401",
      "-401",
      "-1",
    ], //错误状态码
    userFreeTimesCount: {} as NConversation.userFreeTimesCountTs, //用户免费次数
  } as ChatMessage,
  reducers: {
    setData: (state: ChatMessage, { payload }: Payload) => {
      return {
        ...state,
        ...payload,
      };
    },

    setUserFreeTimesCount: (state: ChatMessage, { payload }: Payload) => {
      return {
        ...state,
        userFreeTimesCount: payload,
      };
    },
  },
  effects: {
    // 获取用户免费次数
    *getUserFreeTimes(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      try {
        const { lbChatUserInfo } = (yield select(
          (state: RootState) => state.userInfo,
        )) as NUser.IUserInfoState;

        if (lbChatUserInfo?.loginToken && !lbChatUserInfo?.isVip) {
          const res: any = yield call(userFreeTimes, payload);
          if (res?.code == 0) {
            yield put({
              type: "setUserFreeTimesCount",
              payload: res.data,
            });
          }
          return Promise.resolve(res);
        }
      } catch (err) {
        return Promise.reject();
      }
    },
    *stop(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { cancelToken } = (yield select(
        (state: RootState) => state.chatMessage,
      )) as ChatMessage;

      cancelToken.abort();
    },
    // 对话列表会话
    *chat(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const {
        conversationItem,
        chatTextarea,
        enablePlugins,
        gptMode,
        chatInputImage,
        messagePolledData,
        currentImageId,
      } = (yield select(
        (state: RootState) => state.chatParameter,
      )) as ChatParameter;

      const { siteConfig } = (yield select(
        (state: RootState) => state.site,
      )) as NSite.IModelState;

      let inputVal = chatTextarea;

      const { selectedChatRules, allChatRuleItems } = (yield select(
        (state: RootState) => {
          return state.chatOptimizeQuestion;
        },
      )) as IChatOptimizeQuestionStore;

      if (selectedChatRules.length && allChatRuleItems.length) {
        const optimizeText = allChatRuleItems
          .filter((n) => selectedChatRules.includes(n.id))
          .map((n) => n.content)
          .join(" ");
        inputVal = `${inputVal?.trim()} ${optimizeText}`;
      }

      // 判空是否未输入
      if (!payload && !chatTextarea) {
        message.destroy();
        message.warning("请输入要提问的内容~");
        return;
      }
      let limitNum = siteConfig.ai_chat_input_length;
      if (gptMode.model === "gpt-4" && siteConfig.gpt_plus_enable === "true") {
        limitNum = siteConfig.gpt4_token_length_limit;
      } else if (gptMode.model === "claude") {
        limitNum = siteConfig.claude_ai_chat_input_length;
      } else if (gptMode.model === "o1-mini") {
        limitNum = siteConfig.ai_chat_o1_input_length;
      } else if (gptMode.model === "o1-preview") {
        limitNum = siteConfig.ai_chat_o1_pre_input_length;
      }

      console.log(limitNum, "limitNum");

      if (
        getChatTextLengthTip(chatTextarea || payload.content || "", limitNum)
      ) {
        return;
      }

      // if (getChatTextLengthTip(chatTextarea || payload.content || "",
      // gptMode.model === "gpt-4" && siteConfig.gpt_plus_enable === "true" ? siteConfig.gpt4_token_length_limit : siteConfig.ai_chat_input_length)) {
      //   return;
      // }

      yield put({
        type: "chatParameter/setHasChatStream",
        payload: false,
      });

      // 获取用户信息
      const { lbChatUserInfo } = (yield select(
        (state: RootState) => state.userInfo,
      )) as NUser.IUserInfoState;

      if (!lbChatUserInfo?.loginToken) {
        yield put({
          type: "userParameter/setHasResetPassword",
          payload: false,
        });
        yield put({ type: "userParameter/setHasLoginPop", payload: true });
        // 未登录情况下发送的消息问题
        localStorage.setItem("lb-chat-noLogin-text", payload || chatTextarea);
        return false;
      }

      let { chatMessageList } = (yield select(
        (state: RootState) => state.chatMessage,
      )) as ChatMessage;

      const oldSessionId = getRouterParams().sessionid;

      const sessionId = conversationItem?.id || oldSessionId;

      const cancelToken = new AbortController();
      yield put({
        type: "setData",
        payload: { chatPending: true, cancelToken },
      });

      // // 新会话
      const { chatGptConfig, errorCode } = (yield select(
        (state: RootState) => state.chatMessage,
      )) as ChatMessage;

      const notBindFileCode = errorCode.filter(
        (n) => !["-20004", "-20010"].includes(n as string),
      );

      // 开始流式传输前 解析请求信息
      let requestConfig: ChatGptConfig = {
        ...chatGptConfig,
        fileDataId: chatGptConfig.fileDataId || messagePolledData.fileDataId,
        model: gptMode.model,
        messages: chatMessageList,
        pluginType: conversationItem?.pluginType,
        rolePlayId: conversationItem?.rolePlayId,
        useInternet: conversationItem?.useInternet || 0,
        imageRecognitionId: currentImageId || chatGptConfig.imageRecognitionId,
      };

      // 文件上传参数解析

      if (payload?.type === "file" || payload?.type === "fileList") {
        payload.fileMarkType = "start";

        if (payload?.type === "fileList") {
          requestConfig.gptFileIds = payload.gptFileIds;
        } else {
          requestConfig.fileDataId = payload.id;
        }

        requestConfig.fileMarkType = "start";
      } else {
        requestConfig.fileMarkType = "";
      }

      if (requestConfig.fileDataId) {
        requestConfig.useInternet = 0;
      }

      const beParaphrasing =
        chatMessageList.at(-1)?.operationType === "pre_paraphrasing";
      if (beParaphrasing) {
        requestConfig.operationType = "paraphrasing";
      } else {
        requestConfig.operationType = "question";
      }

      // 预设问题
      if (payload?.operationType) {
        requestConfig.operationType = payload.operationType;
        requestConfig.fileDataId = messagePolledData.fileDataId;
      }
      console.log("requestConfig", requestConfig);

      // 图片解读
      if (gptMode.model === "gpt-4") {
        if (chatInputImage) {
          requestConfig.imageRecognitionId = chatInputImage.imageRecognitionId;
          getDvaStore().dispatch({
            type: "chatParameter/setCurrentImageId",
            payload: chatInputImage.imageRecognitionId,
          });
          requestConfig.useInternet = 0;
        }
      } else {
        requestConfig.imageRecognitionId = "";
      }

      // 问题编辑参数
      if (payload?.editMessageId) {
        const preUserConvaersation = chatMessageList.find((n, index) => {
          return chatMessageList[index + 2]?.id === payload?.editMessageId;
        });

        const switchIndex = chatMessageList.findIndex((n, index) => {
          return n.id === payload?.editId;
        });

        if (switchIndex !== -1) {
          chatMessageList = chatMessageList.slice(0, switchIndex);
          yield put({ type: "setData", payload: { chatMessageList } });

          requestConfig.editChatMessage = true;
          requestConfig.beforeProblemId = preUserConvaersation?.id;
          requestConfig.editMessageId =
            payload.childMessages?.[0]?.id ?? payload.id;
        }
      } else {
        requestConfig.editChatMessage = undefined;
        if (chatMessageList.at(-2)?.messageId !== undefined) {
          requestConfig.beforeProblemId = chatMessageList.at(-2)?.id;
        } else {
          const promptMsgIndex = chatMessageList.findLastIndex(
            (item) => item.messageId !== undefined,
          );
          if (promptMsgIndex !== -1) {
            requestConfig.beforeProblemId =
              chatMessageList[promptMsgIndex - 1]?.id;
          }
        }
      }

      yield put({
        type: "setData",
        payload: { chatGptConfig: requestConfig },
      });

      const newMessages: NConversation.ChatMessageListItem[] = [
        {
          content: payload?.fileName || (inputVal as string),
          isDone: true,
          tempId: Date.now().toString() + "1",
          isError: false,
          id: chatGptConfig.regenerateAnswerId
            ? chatGptConfig.regenerateAnswerId
            : "",
          messageType: 1,
          ...(payload ? payload : {}),
          role: "user",
        },
        {
          isDone: false,
          tempId: Date.now().toString() + "2",
          isError: false,
          id: chatGptConfig.regenerateAnswerId
            ? chatGptConfig.regenerateAnswerId
            : "",
          messageType: 2,
          ...(payload ? payload : {}),
          content: "",
          role: "assistant",
        },
      ];
      let chatMessage = newMessages[1];
      let messageList: any = [];
      let reloadMessageList: any = [];
      if (chatGptConfig && chatGptConfig.regenerateAnswerId) {
        const regenerateIndex = chatMessageList.findIndex(
          (item) => item.id === chatGptConfig.regenerateAnswerId,
        );
        if (regenerateIndex !== -1) {
          chatMessageList[regenerateIndex] = chatMessage;
          reloadMessageList = chatMessageList.slice(0, regenerateIndex);
        }
        messageList = [...chatMessageList];
      } else {
        messageList = [...chatMessageList, ...newMessages];
      }

      // 继续会话
      if (payload?.type === "continue" && chatMessageList.length) {
        chatMessage = chatMessageList.at(
          -1,
        ) as NConversation.ChatMessageListItem;
        messageList = [...chatMessageList];
        requestConfig.isContinue = true;
        chatMessage.isDone = false;
      }

      yield put({
        type: "setData",
        payload: {
          chatMessageList: messageList,
        },
      });

      yield put({ type: "chatParameter/setChatTextarea", payload: "" });

      const serviceUrl = lbChatUserInfo?.loginToken
        ? process.env.Chat_Service_Url
        : process.env.Chat_No_Service_Url;
      const httpUrl = `${API_HOST}${serviceUrl}`;

      let links: string[],
        superAdditionList: Record<string, any>,
        useInternet: number;

      eventSource(httpUrl, {
        method: "post",
        body: JSON.stringify({
          ...requestConfig,
          sessionId,
          messages:
            payload?.type === "continue"
              ? messageList
              : chatGptConfig.regenerateAnswerId
              ? reloadMessageList
              : messageList.slice(0, -1),
        }),
        headers: {
          token: getLoginToken(),
          "Content-Type": "application/json",
          accept: "text/event-stream",
        },
        onmessage(e, originData) {
          if (originData?.model === "error") {
            chatMessage.messageCategory = "error";
          }
          console.log("onmessage");
          superAdditionList = originData?.superAdditionList;
          if (chatMessage.isDone) return;

          let jsonData;
          links = originData?.links;

          useInternet = originData?.useInternet;

          if (e.event == "continue") {
            // 未写完的判断
            getDvaStore().dispatch({
              type: "chatParameter/setHasChatStream",
              payload: true,
            });
          }

          try {
            jsonData = JSON.parse(e.data);
          } catch (e) {}

          if (Object.prototype.toString.call(jsonData) === "[object Object]") {
            if (jsonData.code === -2004) {
              chatMessage.messageCategory = "sensitive";
              chatMessage.sensitiveType = "input";
            }
            if (jsonData.code === -20004) {
              chatMessage.messageCategory = "sensitive";
              chatMessage.sensitiveType = "output";
            }

            if (
              notBindFileCode.includes(jsonData.code?.toString()) &&
              chatMessage.fileType
            ) {
              // 敏感词不会绑定上传文件信息 清空fileDataId
              getDvaStore().dispatch({
                type: "chatMessage/setData",
                payload: {
                  chatGptConfig: {
                    ...getDav().chatMessage.chatGptConfig,
                    fileDataId: "",
                  },
                },
              });
            }
            if (jsonData.code == -20010) {
              getDvaStore().dispatch({
                type: "userParameter/setSqueezeInfo",
                payload: { ...jsonData.data, show: true },
              });
              getDvaStore().dispatch({
                type: "chatParameter/setChatReadingState",
                payload: false,
              });
              return;
            }
            if (errorCode.includes(jsonData?.code?.toString?.())) {
              chatMessage.errCode = jsonData?.code?.toString?.();
              chatMessage.content = jsonData?.msg;
              // 刷新视图
              getDvaStore().dispatch({
                type: "chatMessage/setData",
                payload: {},
              });
              getDvaStore().dispatch({
                type: "chatParameter/setEditDisabled",
                payload: false,
              });
              return;
            }
          }

          // 字符串流
          let str = e.data;
          chatMessage.content += str;
          // 刷新视图
          getDvaStore().dispatch({
            type: "chatMessage/setData",
            payload: {},
          });
        },
        onclose(type?: string) {
          // 清空reload模式的状态判断
          getDvaStore().dispatch({
            type: "chatMessage/setData",
            payload: {
              chatGptConfig: {
                ...chatGptConfig,
                regenerateAnswerId: "",
              },
            },
          });
          console.log(superAdditionList, "superAdditionList");

          chatMessage.superAdditionList = superAdditionList;
          if (chatMessage.isDone) return;
          chatMessage.links = links;
          chatMessage.useInternet = useInternet;
          chatMessage.isDone = true;
          getDvaStore().dispatch({
            type: "chatMessage/setData",
            payload: { chatPending: false },
          });
          getDvaStore().dispatch({
            type: "chatMessage/getUserFreeTimes",
          });

          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用
          updataLeftSideHistory();
          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用  END
          getDvaStore().dispatch({
            type: "leftSideX/refreshConversationCount",
          });
        },
        onerror(errMsg, errInfo) {
          chatMessage.messageCategory = "error";
          if (chatMessage.isDone) return;
          console.log(errInfo, "errInfo");
          chatMessage.isDone = true;
          chatMessage.isError = true;
          chatMessage.canRegenerate = true;
          if (errInfo) {
            chatMessage.errCode = errInfo?.code?.toString?.();
          }
          chatMessage.content = `<font color='red'>${errMsg}</font>`;

          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用
          updataLeftSideHistory();
          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用  END

          getDvaStore().dispatch({
            type: "chatMessage/setData",
            payload: { chatPending: false },
          });
          getDvaStore().dispatch({ type: "chatMessage/stop" });
          if (errInfo) {
            throw Error(errInfo);
          }
        },
        tryTimes: 0,
        timeout: 1000 * 60 * 3,
        signal: cancelToken.signal,
      });

      //
      yield put({
        type: "setData",
        payload: {
          chatGptConfig: {
            ...requestConfig,
            isContinue: undefined,
          },
        },
      });

      // 每次会话结束清空图片信息
      yield put({
        type: "chatParameter/setChatInputImage",
        payload: undefined,
      });
      // 开始流式传输后 解析请求信息
      yield put({ type: "afterRequest", payload: payload });
    },
    // 发起新会话 重置stream参数 拉取sessionId
    *newChat(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const res: any = yield call(conversationSessionId);
      const { enablePlugins, gptMode, chatTextarea } = (yield select(
        (state: RootState) => {
          return state.chatParameter;
        },
      )) as ChatParameter;

      const { chatGptConfig } = (yield select(
        (state: RootState) => state.chatMessage,
      )) as ChatMessage;

      const { siteConfig } = (yield select(
        (state: RootState) => state.site,
      )) as NSite.IModelState;

      // 判空是否未输入
      if (!payload && !chatTextarea) {
        message.destroy();
        message.warning("请输入要提问的内容~");
        return;
      }

      let limitNum = siteConfig.ai_chat_input_length;
      if (gptMode.model === "gpt-4" && siteConfig.gpt_plus_enable === "true") {
        limitNum = siteConfig.gpt4_token_length_limit;
      } else if (gptMode.model === "claude") {
        limitNum = siteConfig.claude_ai_chat_input_length;
      } else if (gptMode.model === "o1-mini") {
        limitNum = siteConfig.ai_chat_o1_input_length;
      } else if (gptMode.model === "o1-preview") {
        limitNum = siteConfig.ai_chat_o1_pre_input_length;
      }

      console.log(limitNum, "limitNum");

      // if (getChatTextLengthTip(chatTextarea || "",
      // gptMode.model === "gpt-4" && siteConfig.gpt_plus_enable === "true" ? siteConfig.gpt4_token_length_limit : siteConfig.ai_chat_input_length)) {
      //   return;
      // }
      if (getChatTextLengthTip(chatTextarea || "", limitNum)) {
        return;
      }

      if (res.code === 0) {
        const pluginType = enablePlugins.join(",");
        const isGpt4 = gptMode.model === "gpt-4";
        yield put({
          type: "chatParameter/setConversationItem",
          payload: {
            id: res.data.sessionId,
            pluginType: isGpt4 ? pluginType : undefined,
          },
        });

        yield put({
          type: "setData",
          payload: {
            chatPending: false,
            chatMessageList: [],
            historyLoading: false,
          },
        });

        const requestConfig: ChatGptConfig = {
          ...chatGptConfig,
          fileDataId: undefined,
          gptFileIds: [],
          isContinue: false,
          promptId: chatGptConfig.promptId || 0,
          sessionId: res.data.sessionId,
          rolePlayId: undefined,
          pluginType: isGpt4 || pluginType,
        };

        yield put({
          type: "setData",
          payload: { chatGptConfig: requestConfig, chatMessageList: [] },
        });

        yield put({ type: "chat", payload: payload });
      }
      return res;
    },
  },
};
