export const codeMap: Record<string, string> = {
  hot_chat: "PopularConversations",
  custom_model: "CustomModel",
  role_play: "RolePlay",
  document_interpretation: "DocumentInterpretation",
  statistical_chart: "StatisticalChartGeneration",
  picture_interpretation: "PictureInterpretation",
  flow_chart: "FlowChartGeneration",
  mind_map: "MindMapping",
  personal_template: "PersonalTemplate",
  hot_imagine: "PopularPaintings",
  image_imagine: "DrawPicturesFromPictures",
  image_describe: "ImageToConversionCommand",
  images_blend: "MultiImageFusion",
  anime_mode: "AnimeMode",
  image_photography: "ImagePhotography",
  anime_style: "AnimationOfRealPictures",
  photo_camera: "PhotoCamera",
  dalle_logo: "DalleLogo",
  Translate: "AITranslation",
  PPT: "PPTGeneration",
  work_log: "WorkLog",
  scout_the_store: "StoreExplorationCopyWriting",
  copy_writing: "LittleRedBookCopyWriting",
  code_generation: "CodeGeneration",
  circle_of_friends: "MomentsCopyWriting",
  recruit: "RecruitmentCopyWriting",
  creative_slogan: "CreativeSlogan",
  live_delivery: "LiveOralBroadcast",
  reading: "AIReading",
  DataAnalysis: "DataAnalysis",
  code_check: "CodeInspection",
  team_building_program: "TeamBuildingPlan",
  short_video_script: "ShortVideoScript",
  paper_thanks: "PaperThanks",
  paper_outline: "PaperOutline",
  paper_rewrite: "PaperModify",
  work_summary: "WorkSummary",
  paper_continue_write: "PaperContinue",
  paper_abstract: "PaperAbstract",
  financial_report: "financial_report",
  event_plan: "EventPlan",
  zoon_video: "ZoonVideo",
  ancient_customs_video: "AncientCustomsVideo",
  modern_video: "ModernVideo",
  science_fiction_video: "ScienceFictionVideo",
  another_video: "AnotherVideo"
};

export const getCodeByType = (type: string) => {
  const keys = Object.keys(codeMap);
  let key = "";

  keys.forEach((item) => {
    if (codeMap[item] === type) {
      key = item;
    }
  });

  return key;
};

// AI 对话类型
export enum EConversationFunctionTypes {
  // 热门对话
  PopularConversations = "PopularConversations",
  // 定制模型
  CustomModel = "CustomModel",
  // 角色扮演
  RolePlay = "RolePlay",
  // 文件解读
  DocumentInterpretation = "DocumentInterpretation",
  // 统计图生成
  StatisticalChartGeneration = "StatisticalChartGeneration",
  // 图片解读
  PictureInterpretation = "PictureInterpretation",
  // 流程图生成
  FlowChartGeneration = "FlowChartGeneration",
  // 思维导图
  MindMapping = "MindMapping",
  // 个人模板
  PersonalTemplate = "PersonalTemplate",
}

// AI 绘画类型
export enum EPaintingFunctionTypes {
  // 热门绘画
  PopularPaintings = "PopularPaintings",
  // 以图生图
  DrawPicturesFromPictures = "DrawPicturesFromPictures",
  // 图片转指令
  ImageToConversionCommand = "ImageToConversionCommand",
  // 多图融合
  MultiImageFusion = "MultiImageFusion",
  // 动漫模式
  AnimeMode = "AnimeMode",
  // 真实图片动漫化
  AnimationOfRealPictures = "AnimationOfRealPictures",
  //写真模式
  PhotoCamera = "PhotoCamera",
  //摄影模式
  ImagePhotography = "ImagePhotography",
  //dalle3Logo
  DalleLogo = "DalleLogo",
}

// AI 视频类型
export enum EVideoFunctionTypes {
  // 动物视频
  ZoonVideo = "ZoonVideo",
  // 古风视频
  AncientCustomsVideo = "AncientCustomsVideo",
  // 现代视频
  ModernVideo = "ModernVideo",
  // 科幻视频
  ScienceFictionVideo = "ScienceFictionVideo",
  // 转场视频
  AnotherVideo = "AnotherVideo",
}

// 生产力类型
export enum EProductiveForcesFunctionTypes {
  // AI翻译
  AITranslation = "AITranslation",
  // PPT生成
  PPTGeneration = "PPTGeneration",
  // 工作日志
  WorkLog = "WorkLog",
  // 代码生成
  CodeGeneration = "CodeGeneration",
  // 小红书文案
  LittleRedBookCopyWriting = "LittleRedBookCopyWriting",
  // 直播带货口播
  LiveOralBroadcast = "LiveOralBroadcast",
  // 数据分析
  DataAnalysis = "DataAnalysis",
  // AI读书
  AIReading = "AIReading",
  // 招聘文案
  RecruitmentCopyWriting = "RecruitmentCopyWriting",
  // 团建方案
  TeamBuildingPlan = "TeamBuildingPlan",
  // 代码检查
  CodeInspection = "CodeInspection",
  // 朋友圈文案
  MomentsCopyWriting = "MomentsCopyWriting",
  // 短视频脚本
  ShortVideoScript = "ShortVideoScript",
  // 探店文案
  StoreExplorationCopyWriting = "StoreExplorationCopyWriting",
  // 创意广告语
  CreativeSlogan = "CreativeSlogan",
  // 论文致谢
  PaperThanks = "PaperThanks",
  // 论文提纲
  PaperOutline = "PaperOutline",
  // 论文改写
  PaperModify = "PaperModify",
  // 工作总结
  WorkSummary = "WorkSummary",
  // 论文续写
  PaperContinue = "PaperContinue",
  // 论文摘要
  PaperAbstract = "PaperAbstract",
  // 财报解读
  Financial = "financial_report",
  // 活动策划
  EventPlan = "EventPlan",
}

// AI 玩法展示所有类型
export type EFunctionMenusTypes =
  | EConversationFunctionTypes
  | EPaintingFunctionTypes
  | EProductiveForcesFunctionTypes
  | EVideoFunctionTypes;

export interface IFunctionMenuItem {
  value: string;
  type: EFunctionMenusTypes;
  disabled?: boolean;
}

// AI 对话
export const ConversationFunctions: IFunctionMenuItem[] = [
  {
    value: "热门对话",
    type: EConversationFunctionTypes.PopularConversations,
  },
  {
    value: "定制模型",
    type: EConversationFunctionTypes.CustomModel,
  },
  {
    value: "角色扮演",
    type: EConversationFunctionTypes.RolePlay,
  },
  {
    value: "文件解读",
    type: EConversationFunctionTypes.DocumentInterpretation,
  },
  {
    value: "统计图生成",
    type: EConversationFunctionTypes.StatisticalChartGeneration,
  },
  {
    value: "图片解读",
    type: EConversationFunctionTypes.PictureInterpretation,
  },
  {
    value: "流程图生成",
    type: EConversationFunctionTypes.FlowChartGeneration,
  },
  {
    value: "思维导图",
    type: EConversationFunctionTypes.MindMapping,
  },
  {
    value: "个人模板",
    type: EConversationFunctionTypes.PersonalTemplate,
  },
];

// AI 绘画
export const PaintingFunctions: IFunctionMenuItem[] = [
  {
    value: "热门绘画",
    type: EPaintingFunctionTypes.PopularPaintings,
  },
  {
    value: "以图生图",
    type: EPaintingFunctionTypes.DrawPicturesFromPictures,
  },
  {
    value: "图片转指令",
    type: EPaintingFunctionTypes.ImageToConversionCommand,
  },
  {
    value: "多图融合",
    type: EPaintingFunctionTypes.MultiImageFusion,
  },
  {
    value: "动漫模式",
    type: EPaintingFunctionTypes.AnimeMode,
  },
  {
    value: "摄影模式",
    type: EPaintingFunctionTypes.ImagePhotography,
  },
  {
    value: "真实图片动漫化",
    type: EPaintingFunctionTypes.AnimationOfRealPictures,
  },
  {
    value: "写真相机",
    type: EPaintingFunctionTypes.PhotoCamera,
  },
  {
    value: "Logo",
    type: EPaintingFunctionTypes.DalleLogo,
  },
];

// AI 绘画
export const VideoFunctions: IFunctionMenuItem[] = [
  {
    value: "动物视频",
    type: EVideoFunctionTypes.ZoonVideo,
  },
  {
    value: "古风视频",
    type: EVideoFunctionTypes.AncientCustomsVideo,
  },
  {
    value: "现代视频",
    type: EVideoFunctionTypes.ModernVideo,
  },
  {
    value: "科幻视频",
    type: EVideoFunctionTypes.ScienceFictionVideo,
  },
  {
    value: "转场视频",
    type: EVideoFunctionTypes.AnotherVideo,
  },
];

// 生产力
export const ProductiveForcesFunctions: IFunctionMenuItem[] = [
  {
    value: "AI翻译",
    type: EProductiveForcesFunctionTypes.AITranslation,
  },
  {
    value: "PPT生成",
    type: EProductiveForcesFunctionTypes.PPTGeneration,
  },
  {
    value: "工作日志",
    type: EProductiveForcesFunctionTypes.WorkLog,
    disabled: true,
  },
  {
    value: "代码生成",
    type: EProductiveForcesFunctionTypes.CodeGeneration,
    disabled: true,
  },
  {
    value: "小红书文案",
    type: EProductiveForcesFunctionTypes.LittleRedBookCopyWriting,
  },
  {
    value: "直播带货口播",
    type: EProductiveForcesFunctionTypes.LiveOralBroadcast,
  },
  {
    value: "数据分析",
    type: EProductiveForcesFunctionTypes.DataAnalysis,
  },
  {
    value: "AI读书",
    type: EProductiveForcesFunctionTypes.AIReading,
  },
  {
    value: "招聘文案",
    type: EProductiveForcesFunctionTypes.RecruitmentCopyWriting,
  },
  {
    value: "团建方案",
    type: EProductiveForcesFunctionTypes.TeamBuildingPlan,
    disabled: true,
  },
  {
    value: "代码检查",
    type: EProductiveForcesFunctionTypes.CodeInspection,
    disabled: true,
  },
  {
    value: "朋友圈文案",
    type: EProductiveForcesFunctionTypes.MomentsCopyWriting,
  },
  {
    value: "短视频脚本",
    type: EProductiveForcesFunctionTypes.ShortVideoScript,
    disabled: true,
  },
  {
    value: "探店文案",
    type: EProductiveForcesFunctionTypes.StoreExplorationCopyWriting,
  },
  {
    value: "创意广告语",
    type: EProductiveForcesFunctionTypes.CreativeSlogan,
  },
  {
    value: "论文致谢",
    type: EProductiveForcesFunctionTypes.PaperThanks,
  },
  {
    value: "论文提纲",
    type: EProductiveForcesFunctionTypes.PaperOutline,
  },
  {
    value: "论文改写",
    type: EProductiveForcesFunctionTypes.PaperModify,
  },
  {
    value: "工作总结",
    type: EProductiveForcesFunctionTypes.WorkSummary,
  },
  {
    value: "论文续写",
    type: EProductiveForcesFunctionTypes.PaperContinue,
  },
  {
    value: "论文摘要",
    type: EProductiveForcesFunctionTypes.PaperAbstract,
  },
  {
    value: "活动策划",
    type: EProductiveForcesFunctionTypes.EventPlan,
  },
];

export const MIN_PC_WIDTH = 1500;
export const MAX_MOBILE_WIDTH = 600;
