import {
  requestVideoChatSend,
  requestVideoVipRemain,
} from "@/server/chat-video";
import { Payload, RootState } from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";
import { conversationSessionId } from "@/server/lb-chat";
import axios from "axios";
import { getRouterParams } from "@/utils/utils";
import { PubSubType } from "@/const";

export interface chatVideoState {
  inputVal: string;
  messageList: NChatVideo.IMessage[];
  loading: boolean;
  showNoBenifitDialog: boolean;
  params: NChatVideo.ISendParams;
  remainVideoCount: number;
}

let cancelChat = () => {};

const getDefaultState = (): chatVideoState => {
  return {
    inputVal: "",
    messageList: [],
    loading: false,
    showNoBenifitDialog: false,
    params: {
      conversationConfigId: undefined,
      conversationId:
        location.pathname === "/ai-video"
          ? getRouterParams()?.sessionid
          : undefined,
      messageContent: "",
      platform: "pc",
      seed: undefined,
    },
    remainVideoCount: 0,
  };
};

export default {
  state: getDefaultState(),
  reducers: {
    setInputVal: (state: chatVideoState, { payload }: Payload) => {
      return {
        ...state,
        inputVal: payload,
      };
    },
    setLoading: (state: chatVideoState, { payload }: Payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setParams: (state: chatVideoState, { payload }: Payload) => {
      return {
        ...state,
        params: { ...state.params, ...payload },
      };
    },
    setMessageList: (state: chatVideoState, { payload }: Payload) => {
      return {
        ...state,
        messageList: payload,
      };
    },
    setMessageItem: (state: chatVideoState, { payload }: Payload) => {
      const newList = state.messageList.map((n) => {
        if (n.id === payload.id) {
          return { ...n, ...payload };
        }
        return n;
      });
      return {
        ...state,
        messageList: newList,
      };
    },
    resetState: (state: chatVideoState, { payload }: Payload) => {
      return getDefaultState()
    },
    setRemainVideoCount: (state: chatVideoState, { payload }: Payload) => {
      return {
        ...state,
        remainVideoCount: payload,
      };
    },
    setShowNoBenifitDialog: (state: chatVideoState, { payload }: Payload) => {
      return {
        ...state,
        showNoBenifitDialog: payload,
      };
    },
  },
  effects: {
    // 视频对话
    *chat(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      // // 新会话

      const { isNew } = payload;
      const { params, inputVal, messageList } = (yield select(
        (state: RootState) => state.chatVideo,
      )) as chatVideoState;

      const newParams = { ...params, messageContent: inputVal };

      yield put({ type: "setParams", payload: newParams });

      yield put({ type: "setLoading", payload: true });

      try {
        const chatResult: any = yield call(requestVideoChatSend, newParams, {
          cancelToken: new axios.CancelToken((c) => (cancelChat = c)),
        });

        if (chatResult.code === 0) {
          const newList = [...messageList, chatResult.data];
          yield put({ type: "setMessageList", payload: newList });
          yield put({ type: "setInputVal", payload: "" });
        }
        yield put({ type: "getRemainVideoCount" });
        yield put({ type: "setLoading", payload: false });
        return chatResult;
      } catch (err) {
        yield put({ type: "setLoading", payload: false });
        return err;
      }
    },
    // 视频对话
    *newChat(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      // const res: any = yield call(conversationSessionId);

      const { params } = (yield select(
        (state: RootState) => state.chatVideo,
      )) as chatVideoState;

      // if (res.code === 0) {
      //   const newParams = {
      //     ...params,
      //     conversationId: res.data.sessionId as string,
      //   };

      // yield put({ type: "setParams", payload: newParams });
      // }

      const result: any = yield put({ type: "chat", payload: { isNew: true } });

      return result;
    },
    *stop(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      cancelChat();
    },
    *getRemainVideoCount(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      // 从全局状态获取登录状态，根据你的实际状态管理方式调整
      const isLogin = yield select((state: RootState) => state.userInfo.lbChatUserInfo);
      
      // 未登录直接返回，设置剩余次数为 0
      if (!isLogin) {
        yield put({
          type: "setRemainVideoCount",
          payload: 0,
        });
        return;
      }

      try {
        const result: any = yield call(requestVideoVipRemain);
        if (result.code === 0) {
          yield put({
            type: "setRemainVideoCount",
            payload: result.data,
          });
        }
        return result;
      } catch (err) {
        return err;
      }
    },
  },
};
