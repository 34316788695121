import { AxiosRequestConfig } from "axios";
import http, { catch_http, httpPlus } from "./request";

// 会员列表
export const requestVideoVipList = () => {
  return http<NChatVideo.IVideoPackage[]>(
    "get",
    "/chat-server/api/video/vip/list",
  );
};

// 用户剩余会员次数
export const requestVideoVipRemain = () => {
  return http<number>("get", "/chat-server/api/video/vip/user/count");
};

// 获取用户有效视频会话数量
export const requestVideoConversationCount = () => {
  return http<number>("post", "/chat-server/api/video/conversation/count");
};

// 删除会话
export const requestDeleteVideoConversation = (idList: string[]) => {
  return http("post", `/chat-server/api/video/conversation/delete-batch`, {
    idList,
  });
};

// 获取视频记录详情
export const requestVideoDetail = (data: { id: string }) => {
  return http("post", `/chat-server/api/video/conversation/get-detail`, data);
};

// 获取视频分页查询
export const requestVideoList = (data: { current: number; size: number }) => {
  return catch_http(
    "post",
    `/chat-server/api/video/conversation/page-of-list`,
    data,
  );
};

// 视频修改
export const requestVideoUpdate = (data: {
  id: string;
  pinned: boolean | null;
  title: string;
}) => {
  return http("post", `/chat-server/api/video/conversation/update/info`, data);
};

// 视频对话删除消息
export const requestDeleteVideoMessage = (id: string) => {
  return httpPlus("get", `/chat-server/api/video/message/delete/${id}`);
};

// 视频消息历史记录
export const requestVideoChatMessageList = (
  data: {
    conversationId: string;
    current: number;
    size: number;
  },
  config?: AxiosRequestConfig,
) => {
  return httpPlus<NCommon.RecordResponse<NChatVideo.IMessage>>(
    "post",
    `/chat-server/api/video/message/list-of-history`,
    data,
    config
  );
};

// 视频消息历史记录
export const requestVideoChatRetry = (data: {
  conversationId: string;
  id: string;
}) => {
  return httpPlus("post", `/chat-server/api/video/message/retry`, data);
};

// 视频消息历史记录
export const requestVideoChatSend = (
  data: NChatVideo.ISendParams,
  config?: AxiosRequestConfig,
) => {
  return http<NChatVideo.IMessage>(
    "post",
    `/chat-server/api/video/message/send`,
    data,
    config,
  );
};

// 视频消息历史记录
export const requestVideoChatTask = (
  data: {
    conversationId: string;
    id: string;
  },
  config?: AxiosRequestConfig,
) => {
  return http<NChatVideo.IMessage>(
    "post",
    `/chat-server/api/video/message/task-query`,
    data,
    config,
  );
};

// 视频创作展示列表
export const requestVideoFeatureDisplay = (
  data: {
    current: number;
    featureCode: string;
    size: number;
  },
  config?: AxiosRequestConfig,
) => {
  return http<NCommon.RecordResponse<NChatVideo.IMessage>>(
    "post",
    `/chat-server/api/feature/video/display/page`,
    data,
    config,
  );
};

// 视频创作展示列表
export const requestVideoPromptList = () => {
  return httpPlus<NChatVideo.IPromptGroup[]>(
    "get",
    `/chat-server/api/video/prompt/list`,
  );
};
